@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
body {
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}
@media (min-width: 1366px) {
  .home-page-main .sidebar-main1 {
    position: fixed;
    width: 100%;
    top: 85px;
    max-width: 404px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
  .sidebar-main1 {
    max-width: 335px;
    position: fixed;
    width: 100%;
    top: 85px;
  }
  .videos-tabs nav.nav-tabs {
    position: fixed;
    top: 65px;
    background-color: #eee;
    padding: 10px 10px 0;
    z-index: 1;
    width: 703px;
  }
}

@media (min-width: 1366px) {
  .container {
    max-width: 1300px;
  }
  .videos-tabs nav.nav-tabs {
    position: fixed;
    top: 65px;
    background-color: #eee;
    padding: 10px 10px 0;
    z-index: 1;
    width: 837px;
}
}
.videos-tabs .tab-content {
  margin-top: 32px;
}

p {
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header-section {
  padding: 10px 0;
  margin-bottom: 0px;
  width: 100%;
  background-color: #000;
}
.header-section a.navbar-brand {
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
}
a.navbar-brand {
  display: inline-block;
}
.school-logo-box {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: center;
  box-shadow: 0px 0px 10px #ddd;
}
.school-logo-box p {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 600;
}
.school-logo-box img {
  max-height: 70px;
  max-width: 210px;
  width: 100%;
  object-fit: contain;
}
form.filter-form .form-group {
  margin-bottom: 12px;
}
.school-bottom-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 10px #ddd;
}

form.filter-form .form-group input:focus{
  outline: none;
  box-shadow: none;
  border-color: #ced4da;
}

form.filter-form .form-group input {
  border-radius: 0;
  height: 30px;
  font-size: 14px;
}

.school-bottom-content p {
  font-weight: 500;
  text-align: left;
  font-size: 16px;
}
.form-group select {
  border-radius: 0;
  border-color: #d8d8d8;
  border-width: 1px;
  color: rgb(255 96 121);
  font-weight: 600;
  font-size: 12px;
  border-left: 0;
  height: 30px;
}
.modal-header .close:focus {
  outline: none;
}
.form-group select:focus {
  outline: none;
  box-shadow: none;
  border-color: #d8d8d8;
  color: rgb(255 96 121);
}
.form-group label.form-label {
  margin: 0;
  border: 1px solid #d8d8d8;
  border-right: 0;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  height: 30px;
  line-height: 28px;
  width: 90px;
}
.form-group {
  display: flex;
  align-items: center;
}
.home-top-details-select {
  padding: 4px 15px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 0 10px #ddd;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .home-top-details-select{
    height: 340px;
    overflow: auto;
    margin-bottom: 0;
  }
}

.home-top-details-select h2 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 5px 0;
}

.practice-questions-box .card img.card-img-top {
  height: 105px;
  width: 180px;
  border-radius: 0;
  border-radius: 10px 0 0 10px;
}
.practice-questions-box .card {
  flex-direction: inherit;
  border-color: #d8d8d8;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px #ddd;
}
.practice-questions-box .card .card-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  padding-left: 20px;
}
.practice-questions-box .card .card-body .card-title.h5 {
  font-size: 16px;
  margin: 0;
  color: #000;
  padding: 2px;
  display: block;
}
.practice-questions-box .card .card-body .card-title.h5 b {
  padding-left: 0;
}
.practice-questions-box .card .card-body .card-title.h5 span {
  display: block;
  margin: 0;
  color: #333;
  font-weight: 400;
}
.practice-questions-box .card .card-body button {
  background-color: rgb(255 96 121);
  border-radius: 0 10px 10px 0;
  height: 100%;
  border: none;
  min-width: 70px;
}
.practice-questions-box {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 10px 10px 12px;
  box-shadow: 0 0 10px #ddd;
}
.practice-questions-box .share-link-text {
  text-align: right;
  margin: 0;
  font-size: 12px;
  color: #000;
  font-weight: 600;
}
.submit-btn {
  text-align: center;
}
.question-right-icon ul {
  padding: 0;
  margin: 0;
  text-align: center;
}
.question-right-icon ul li {
  list-style: none;
  display: inline-block;
  margin-left: 20px;
}
.question-right-icon ul li:first-child {
  margin-left: 0;
}
.question-right-icon ul li a {
  height: 65px;
  width: 60px;
  display: block;
  border-radius: 100px;
  /* box-shadow: 0px 3px 6px #00000029; */
  text-align: center;
  padding: 10px;
  text-decoration: none;
}
.question-right-icon ul li a img {
  width: auto;
  display: block;
  margin: 0 auto;
  height: 35px;
  opacity: 0.7;
}
.question-right-icon ul li a span {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  line-height: 1;
  margin-top: 5px;
}
.question-heading h1 {
  font-size: 22px;
  text-align: center;
  color: #000;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0;
  box-shadow: 0 0 10px #ddd;
}
.start-question-content {
  background-color: #fff;
  padding: 30px;
  /* margin-top: 25px; */
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px #ddd;
}
.start-question-content-text {
  margin: 100px 0;
}
.start-question-content-text h2 {
  font-size: 24px;
  color: #000;
  font-weight: 400;
  margin-bottom: 10px;
}
.start-question-content-text h2 span {
  color: rgb(255 96 121);
  font-weight: 600;
}
.start-question-content-text h3 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-bottom: 40px;
}
.start-question-content-text h3 span {
  font-weight: 400;
}
.start-question-content-text h4 {
  font-size: 22px;
  font-weight: 400;
  color: #000;
}
.btn {
  background-color: #000;
  color: #fff;
  font-weight: 400;
  border-radius: 4px;
  border: none;
  min-width: 112px;
  min-height: 40px;
  text-align: center;
  text-transform: capitalize;
}
.btn:hover {
  background-color: rgb(255 96 121);
  color: #fff;
}
.btn:active,
.btn:focus {
  background-color: rgb(255 96 121) !important;
  box-shadow: none !important;
  opacity: 0.8;
  color: #fff !important;
}
.practice-questions-box a {
  text-decoration: none;
}
.start-question-content button {
  text-transform: capitalize;
  font-weight: 400;
}
.start-question-box {
  text-align: left;
}
.question-Img h2 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
}
.question-Img img {
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
}
.question-radio-group {
  margin-bottom: 50px;
}
.question-radio-group .form-check {
  margin-bottom: 20px;
}
.question-radio-group .form-check label {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin-left: 15px;
  margin-top: 3px;
  display: flex;
}
input[type='radio'] {
  margin-top: 5px;
}

input[type='radio']:after {
  width: 22px;
  height: 22px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #000;
}

input[type='radio']:checked:after {
  width: 22px;
  height: 22px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #dc1d3c;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 5px solid #ddd;
}

.success-message {
  margin: 120px 0;
}
.success-message h2 {
  font-size: 22px;
  font-weight: 400;
  color: #000;
  margin-bottom: 15px;
}
.success-message h3 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-bottom: 30px;
}
.success-message p {
  color: #418200;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}
.success-button button.btn {
  background-color: #418200;
  font-size: 18px;
  font-weight: 400;
  min-width: 150px;
  min-height: 42px;
}
.success-message .question-right-icon ul {
  text-align: center;
  margin-top: 50px;
}
.success-message .question-right-icon ul li {
  margin: 0;
}
.pagination-main p {
  color: rgb(255 96 121);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}
.pagination-main {
  text-align: center;
  margin-bottom: 40px;
}
.tic-tca-logo-content {
  text-align: center;
  margin-top: 60px;
}
.tic-tca-logo-content img {
  margin-bottom: 30px;
}
.school-bottom-content a.start-btn.btn {
  line-height: 33px;
}
.school-bottom-content {
  text-align: center;
}
.home-page-main {
  margin-bottom: 30px;
}
.videos-details h1 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: -20px;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 15px 0;
  border-radius: 4px;
}
.video-player {
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
}
.video-next-prev-btn {
  margin-top: 30px;
}
.practice-questions-box .card .card-body .card-title.h5 img {
  margin-left: 5px;
  height: 20px;
  width: 30px;
  object-fit: contain;
}
.share-icon ul li {
  display: inline-block;
  width: 33%;
  text-align: center;
}
.share-icon ul li a img {
  display: block;
  margin: 0 auto;
}
.share-icon ul li a span {
  color: #000;
  font-size: 14px;
}
.share-icon ul li a {
  display: block;
  text-align: center;
  text-decoration: none;
}
.share-icon ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

/* .correct-answer-hl {
  border: 3px green solid;
} */

.correct-answer-hl label .option-images,
  .correct-answer-hl label {
  color: green !important;
}
.wrong-answer-hl label .option-images,
.wrong-answer-hl label {
  color: red !important;
}


div.card-title.h5 > b {
  padding-left: 5px;
}

form.filter-form .submit-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
form.filter-form .submit-btn button {
  border-radius: 8px;
  background-color: rgb(255 96 121);
}
.copied-link a {
  font-size: 14px;
  display: block;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  margin-top: 30px;
}
.share-icon ul li:nth-child(2) a img {
  padding: 0;
}
.btn-primary.disabled,
.btn-primary:disabled {
  opacity: 0.3;
  color: #000;
  background-color: #ddd;
  border-color: #ddd;
}
.start-question-content button.next img {
  margin-left: 4px;
}
.success-message .success-button a.btn {
  line-height: 28px;
}
.success-message .success-button button.btn {
  margin-bottom: 15px;
}
.watching-video-modal .modal-body h5 {
  font-size: 18px;
}
.watching-video-modal .modal-footer {
  justify-content: space-between;
}
.watching-video-modal .modal-footer a.btn {
  line-height: 28px;
}


.question-radio-group .form-check label img {
  width: auto;
  max-width: 100%;
  max-height: 150px;
  height: auto;
  margin-left: 10px;
  border: solid 1px #ddd;
  padding: 15px;
}
.solution-images img {
  max-width: 260px !important;
  display: block;
  margin: 15px auto;
}
/* .videos-tabs nav.nav-tabs {
  margin-bottom: 20px;
  border: none;
} */
.videos-tabs nav.nav-tabs a.nav-item {
  /* border: solid 2px #ff60795c; */
  /* border-radius: 2px; */
  /* margin: 0 6px; */
  color: #000000;
}
.videos-tabs nav.nav-tabs a.nav-item.active {
  /* background-color: #ff6079; */
  color: #000;
}
.copied-link .form-group {
  margin-bottom: 10px;
  background-color: #dddddd45;
  padding: 10px;
  margin-top: 30px;
}
.copied-link .form-group input.form-control {
  background-color: transparent;
  border: none;
  height: 25px;
  border-radius: 0;
  padding: 0 10px;
  color: #4e7bfe;
  font-size: 14px;
}
.copied-link .form-group button.btn {
  background-color: transparent;
  color: #000;
  font-weight: 600;
  padding: 0;
  min-height: 25px;
  min-width: 60px;
}
.copied-link .form-group button.btn:focus,
.copied-link .form-group button.btn:active {
  background-color: rgb(246 246 246) !important;
  color: #000 !important;
}
.video-player .video-player-react {
  margin: 0 auto;
}
.practice-questions-box .card img.practice-ques-img {
  object-fit: contain;
  padding: 10px 0;
  border: solid 1px #ddd;
}
.portal-header a.navbar-brand img {
  max-width: 180px;
}

.videos-tabs nav.nav-tabs a.nav-item span.badge {
  background-color: rgb(255 96 121);
  color: #fff;
  margin-left: 4px;
}
h2.text-question-main span {
  margin-right: 5px;
}
h2.text-question-main {
  display: flex;
}
h3.question-error {
  text-align: center;
  font-size: 24px;
}
.practice-questions-box .card .card-body button span {
  display: block;
}
.option-number-space {
  padding-left: 5px;
}
.radio-with-imgpopup label .option-images {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.radio-with-imgpopup {
  display: flex;
}
.radio-with-imgpopup label img {
  border: solid 1px #ddd;
  padding: 10px;
  margin-left: 15px;
}
.radio-with-imgpopup label .option-images img {
  max-width: 250px !important;
  width: 100%;
}
.radio-with-imgpopup label .option-images .__react_modal_image__modal_content img {
  max-width: initial !important;
  width: auto;
}
.solution-images .__react_modal_image__modal_content img {
  max-width: inherit !important;
}
.sidebar-for-mobile {
  display: none;
}
div#noanim-tab-example-tabpane-playlist .card a img.card-img-top {
  height: auto;
}
.share-popup .modal-footer {
  justify-content: center;
}
.share-popup .modal-footer button {
  min-width: 150px;
}
form.filter-form .submit-btn button {
  width: 48%;
  border-radius: 4px;
}
.school-logo-for-mobile {
  display: none;
}
.mobile-view-logo {
  display: none;
}

button img {
  width: 10px;
  margin-top: -2px;
}

.sidebar-new-search-field .submit-btn button {
  border-radius: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 80px !important;
  width: 80px !important;
  min-height: 30px;
  height: 30px;
  line-height: 1;
  font-size: 14px;
}
.sidebar-new-search-field {
  position: relative;
}

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: #22a6b3;
  padding-right: 40px;
  color:#fff;
}
.input-search::placeholder{
  color:rgba(255,255,255,.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:#ffffff ;
  background-color:transparent;
  pointer-events: painted;  
}
.btn-search:focus ~ .input-search{
  width: 100%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 100%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}



/*  footer   */
footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida  Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
footer .fa-heart{
    color: red;
}
footer .fa-dev{
    color: #fff;
}
footer .fa-twitter-square{
  color:#1da0f1;
}
footer .fa-instagram{
  color: #f0007c;
}
fotter .fa-linkedin{
  color:#0073b1;
}
footer .fa-codepen{
  color:#fff
}
footer a {
    color: #3c97bf;
    text-decoration: none;
  margin-right:5px;
}
.youtubeBtn{
    position: fixed;
    left: 50%;
  transform:translatex(-50%);
    bottom: 45px;
    cursor: pointer;
    transition: all .3s;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
}
.youtubeBtn i{
   font-size:20px;
  float:left;
}
.youtubeBtn a{
    color:#ff0000;
    animation: youtubeAnim 1000ms linear infinite;
  float:right;
}
.youtubeBtn a:hover{
  color:#c9110f;
  transition:all .3s ease-in-out;
  text-shadow: none;
}
.youtubeBtn i:active{
  transform:scale(.9);
  transition:all .3s ease-in-out;
}
.youtubeBtn span{
    font-family: 'Lato';
    font-weight: normal;
    color: #fff;
    display: block;
    font-size: 12px;
    float: right;
    line-height: 20px;
    padding-left: 5px;
  
}

@keyframes youtubeAnim{
  0%,100%{
    color:#c9110f;
  }
  50%{
    color:#ff0000;
  }
}
.desktop-search-bar {
  position: sticky;
  top: 78px;
  z-index: 111;
  width: 35%;
  margin-left: auto;
}
.home-top-details-select .filter-form .form-group:nth-child(2) {
  display: none;
}