@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&display=swap');
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-family: "Roboto", Arial, Tahoma, sans-serif;
  }
  h1, h2, h3, h4, .text-logo #logo {
    font-family: "Roboto", Arial, Tahoma, sans-serif;
}
  
  /* web page css start here  */
  
  /* web page css end here  */
  
  #Top_bar.loading {
    display: block !important;
    top: 0;
    /* position: fixed; */
    z-index: 1010;
  }
  #Top_bar .top_bar_left {
    width: 100% !important;
  }
  #Top_bar .menu_wrapper {
    float: right !important;
  }
  .content {
    padding-top: 0 !important;
  }
  div#mfn-rev-slider .home-banner {
    position: relative;
    top: 75px;
  }
  div#mfn-rev-slider .home-banner .banner-contant {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 460px;
  }
  .home-banner .banner-contant h1 {
  font-family: 'Raleway', sans-serif;
    text-align: left;
    line-height: 49px;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 44px;
    color: rgb(255, 255, 255);
  }
  .home-banner .banner-contant p {
    font-size: 15px;
    color: rgb(255, 255, 255);
    text-align: left;
    margin-bottom: 0;
  }
  
  div#mfn-rev-slider .home-banner .banner-image img {
    width: 100%;
  }
  .slick-next:before,
  .slick-prev:before {
    color: #ddd !important;
  }
  .partners-slider .slick-track {
    display: flex;
    align-items: center;
  }
  .quick-preview-slider {
    margin-top: 50px;
  }
  
  div#quick-preview-sec {
    clear: both;
  }
  .ticTacLearn-testimonials-slider {
    padding: 100px 0;
  }
  .ticTacLearn-testimonials-slider .slider-content p {
    color: #fff;
    font-size: 19px;
    line-height: 27px;
    padding: 0px 94px;
  }
  .ticTacLearn-testimonials-slider .slider-content {
    text-align: center;
  }
  .ticTacLearn-testimonials-slider .slider-content strong {
    font-size: 17px;
    color: #fff;
  }
  .hello-form .form-group input.form-control {
    width: 100%;
    margin-bottom: 0;
    border: 0;
    border-radius: 100px;
  }
  .cnnt-frm button[type='submit'] {
    border-radius: 30px !important;
    position: relative;
    left: 42%;
    padding: 14px 40px;
    margin-top: 38px;
    background-image: url(./images/TicTac-Learn-Website_element-32.png);
    background-size: cover;
    /* z-index: 100; */
    background-color: transparent;
    color: #dc1d3c;
    font-size: 1px;
    width: 194px;
    height: 66px;
  }
  .cnnt-frm button[type='submit']:hover {
    background-image: url(./images/TicTac-Learn-Website_element-33.png);
  }
  
  .faqs-accordion .accordion .card {
    background-color: #e0e0e0 !important;
    border-right: 0;
    border-left: 0;
    border-top: 0;
    border-radius: 0;
    margin-bottom: 10px;
    box-shadow: none;
    position: relative;
  }
  .faqs-accordion .accordion .card .card-header {
    background-color: #e0e0e0;
  }
  .faqs-accordion .accordion .card .card-header button.btn {
    margin: 0;
    display: block;
    width: 100%;
    text-align: left;
    padding-left: 0;
    background-color: #e0e0e0;
    color: #444;
    font-size: 14px;
    box-shadow: none;
  }
  .faqs-accordion .accordion .card .card-header button.btn:active,
  .faqs-accordion .accordion .card .card-header button.btn:focus {
    background-color: rgb(224 224 224) !important;
    color: #dc1d3c !important;
  }
  .faqs-accordion .accordion .card .card-body p {
    color: #737e86;
  }
  .wep-page-header .navbar-nav a {
    color: #fff !important;
  }
  .wep-page-header nav.navbar {
    background-color: #000;
  }
  
  div#whylearn {
    padding-top: 90px;
  }
  div#tictaclearncontent {
    padding-top: 90px;
  }
 
  
  section.macking{
      background-color: #fff;
  }

  .vc_custom_1589455728436{
    background-image: url(./images/lower.gif)
  }
  .testimob {
    background-image: url(./images/testimmonial.png);
    background-size: cover;
  }
  .macking-learning-sec {
    padding: 150px 0 100px;
    background-color: #fff;
}
.macking-content h2 {
  font-size: 45px;
  color: rgb(220, 29, 60);
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-family: "Roboto", Arial, Tahoma, sans-serif;
}
.macking-learning-sec .macking-content {
  text-align: center;
}
.macking-content h3 {
  font-size: 30px;
  text-align: center;
font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 15px;
}
.macking-content p {
  margin-bottom: 30px;
  font-size: 14px;
}
.macking-content p {
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 2;
}
.Why-TicTacLearn {
  background-color: #dc1d3c;
  padding: 100px 0;
}
.Why-TicTacLearn h3 {
  font-size: 45px;
  color: rgb(255, 255, 255);
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 50px;
}
.Why-TicTacLearn-box {
  text-align: center;
  height: 215px;
}
.Why-TicTacLearn-box p {
  color: #fff;
  padding-top: 14px;
  line-height: 21px;
  font-size: 17px;
  width: 40%;
  margin: 0 auto;
}
.Why-TicTacLearn-box .overlay h4 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 21px;
  line-height: 25px;
  font-weight: 500;
  letter-spacing: 0px;
}
.Why-TicTacLearn-box .overlay p {
  font-size: 14px;
  width: 100%;
}
.Why-TicTacLearn-box:hover > p,
  .Why-TicTacLearn-box:hover img {
  display: none;
}
.Why-TicTacLearn-box .overlay {
  display: none;
}
.Why-TicTacLearn-box:hover .overlay{
  display: block;
}
.tictaclearn-content {
  background-color: #fff;
  padding: 100px 0;
}
.tictaclearn-content h2 {
  font-size: 45px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 40px;
}
.tictaclearn-content-box {
  text-align: center;
}
.tictaclearn-content-box h2 {
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 0px;
  color: #dc1d3c;
  margin: 0px;
  text-align: center;
}
.tictaclearn-content-img-box .hover-after-img {
  display: none;
}
.tictaclearn-content-img-box:hover .hover-before-img {
  display: none;
}
.tictaclearn-content-img-box:hover .hover-after-img{
  display: block;
}
.tictaclearn-content-img-box {
  text-align: center;
  display: inline-block;
  height: 320px;
  width: 350px;
  overflow: hidden;
}
.tictaclearn-content-box p {
  font-weight: 600;
}

.quick-preview-sec {
  background-color: #f1f2f2;
  padding: 100px 0;
}
.quick-preview-content {
  text-align: center;
}
.quick-preview-content h2 {
  font-size: 45px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
.quick-preview-content p {
  text-align: center;
  font-size: 17px;
}
.slider-videos h5 a {
  color: #000;
  font-size: 16px;
}
.videos-bottom-box .hover_box {
  text-align: center;
  line-height: 0;
  display: inline-block;
}
.hover_box a {
  display: block;
  line-height: 0;
}
.hover_box .hover_box_wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  line-height: 0;
  max-width: 100%;
}
img.scale-with-grid, #Content img {
  max-width: 100%;
  height: auto;
}
.hover_box .hover_box_wrapper .visible_photo, .hover_box .hover_box_wrapper .hidden_photo {
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
.hover_box .hover_box_wrapper .visible_photo {
  opacity: 1;
}
.hover_box .hover_box_wrapper img {
  display: block;
}
.languagebtn img {
  width: 200px;
}
.hover_box .hover_box_wrapper .hidden_photo {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.hover_box:hover .hover_box_wrapper .hidden_photo, .hover_box.hover .hover_box_wrapper .hidden_photo {
  opacity: 1;
}
.hover_box:hover .hover_box_wrapper .visible_photo, .hover_box.hover .hover_box_wrapper .visible_photo {
  opacity: 0;
}
.videos-bottom-box {
  text-align: center;
  margin-top: 40px;
}
.videos-bottom-img p {
  font-size: 26px;
}

.testimonials-sec {
  background-image: url(./images/testimmonial.png);
  background-size: cover;
  padding: 100px 0;
  background-color: #fff;
}

.testimonials-sec-content h3 {
  font-size: 45px;
  color: rgb(255, 255, 255);
  text-align: center;
  font-family: 'Raleway', sans-serif;
  margin-top: 50px;
}
.testimonials-sec .ticTacLearn-testimonials-slider {
  padding: 50px 0;
}

.testimonials-sec .slick-prev:before{
  background-image: url(./images/previous-arrow-white.png);
}
.testimonials-sec .slick-next:before{
  background-image: url(./images/next-arrow-white.png);
}

.supported-by-sec h3,
.partners-content h2 {
  font-size: 45px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 40px;
}
.Partners-slider-sec {
  padding: 100px 0;
  background-color: #fff;
}
.supported-by-sec {
  padding: 100px 0;
  background-color: #fff;
}
.supported-img-box {
  text-align: center;
}
.stranger-form-sec{
  background-image: url(./images/lower.gif);
  background-size: cover;
  padding: 300px 0 100px;
  background-color: #fff;
}
.stranger-form-content h3 {
  text-align: center;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  font-size: 40px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}
.stranger-form-content p {
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  line-height: 25px;
  margin-bottom: 26px;
}
.faqs-sec {
  background-color: #e0e0e0;
  padding: 100px 0;
}
.faqs-content h3 {
  font-size: 45px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 40px;
}

.footer-sec {
  background-color: #dc1d3c;
  padding: 80px 0;
  position: relative;
}
.footer-menu1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-menu1 ul li a {
  color: #fff;
  font-size: 14px;
}
.footer-menu1 ul .footer-contact-title strong {
  color: #fff;
  margin-top: 20px;
  display: block;
}
.footer-menu2 h3 {
  color: #fff;
  font-size: 14px;
  border-bottom: solid 1px #fff;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.footer-menu2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 50% 50%;
}
.footer-menu2 ul li a {
  color: #fff;
  font-size: 14px;
}
.scroll-top a {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.scroll-top a img {
  transform: rotate(
-90deg
);
}
section.tictac-moments-sec {
  padding: 100px 0;
  background-color: #fff;
}
.tictac-moments-content h2 {
  font-size: 45px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 50px;
  color: #212529;
}
.moments-counter {
  text-align: center;
}
.moments-counter span {
  color: #dc1d3c;
  font-weight: 700;
  font-size: 60px;
}
.moments-counter p {
  color: #2a2627;
  font-weight: 600;
}
.moments-counter span.count-after-text:after {
  content: "+";
  padding-left: 10px;
}









/* =================================================================================== Slider ====================================================================================== */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
    max-width: 60%;
    width: 100%;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */
/* .slick-loading .slick-list
{
    background: #fff url('./ajax-loader.gif') center center no-repeat;
} */

/* Icons */
/* @font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('./fonts/slick.eot');
    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
} */
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '';
    background-image: url(./images/previous-arrow.png);
    height: 20px;
    width: 20px;
    display: block;
    background-repeat: no-repeat;
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
  content: '';
  background-image: url(./images/next-arrow.png);
  height: 20px;
  width: 20px;
  display: block;
  background-repeat: no-repeat;
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}
.scroll-to-top aside.ScrollUpButton__Container {
  fill: #fff !important;
  background-color: #ddd !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-radius: 5px;
}
