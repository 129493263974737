/* Ipad Pro */

@media (max-width: 1024px) {
  .home-top-details-select, .school-logo-box {
    min-height: 170px;
    padding: 0;
  }
  .school-logo-box p {
    font-size: 16px;
  }
  .school-bottom-content p {
    font-size: 14px;
    text-align: center;
  }
  .sidebar-main1 {
    max-width: 288px;
    position: fixed;
    width: 100%;
    top: 85px;
  }
  .wep-page-header nav.navbar .navbar-nav a {
    font-size: 12px;
  }
  .wep-page-header nav.navbar a#logo {
    max-width: 150px;
  }
  .wep-page-header {
    margin-bottom: -5px;
  }
  .videos-tabs nav.nav-tabs {
    position: fixed;
    top: 65px;
    background-color: #eee;
    padding: 10px 10px 0;
    z-index: 1;
    width: 611px;
  }
  .videos-tabs .tab-content {
    margin-top: 32px;
  }
  div#mfn-rev-slider .home-banner {
    top: 68px;
  }
}

@media (max-width: 991px) {
  .footer-logo img {
    width: 100%;
  }
  div#mfn-rev-slider .home-banner {
    top: 70px;
  }
  .footer-menu1 ul li a {
    font-size: 12px;
  }
  div#preloader img {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .wep-page-header nav.navbar .navbar-nav a.toggle-custom {
    position: absolute;
    bottom: -100vh;
    height: 100vh;
    background-color: transparent;
    width: 109%;
    left: -15px;
    color: transparent !important;
  }
  .navbar-collapse {
    transition-delay: 0.3s;
  }
  .sidebar-main {
    margin-bottom: 30px;
  }
  .wep-page-header .container {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .wep-page-header .container button.navbar-toggler {
    color: #fff !important;
    background-color: #fff;
    padding: 5px;
    margin-right: 0;
    margin-bottom: 0;
    position: absolute;
    top: 10px;
    right: 15px;
  }
  .wep-page-header {
    margin-bottom: -10px;
  }
  .wep-page-header {
    position: relative;
  }
  .school-page .school-bottom-content {
    width: 100%;
    max-width: 100%;
    min-height: auto;
    text-align: center;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .school-logo-box {
    max-width: 100%;
    float: left;
    width: 100%;
    margin-right: 4%;
    min-height: 338px;
    padding-top: 11%;
  }
  .school-bottom-content {
    max-width: 48%;
    float: left;
    width: 100%;
    min-height: 330px;
  }
  .sidebar-main1 {
    max-width: inherit;
    position: relative;
    width: 100%;
    top: inherit;
    display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
  }
  .school-logo-box img {
    max-height: 210px;
    max-width: 210px;
    width: 100%;
    object-fit: contain;
  }
  .videos-tabs nav.nav-tabs {
    position: inherit;
    top: inherit;
    background-color: transparent;
    padding: 0;
    z-index: 1;
    width: inherit;
  }
  .videos-tabs .tab-content {
    margin-top: 0;
  }
}

@media (max-width: 767px) {

  form.filter-form .form-group {
    margin-bottom: 10px;
}

  .new-search-bar-for-mobile .open-search .search {
    display: block;
    margin: 0 auto;
}
.active.new-search-bar-for-mobile .open-search .search {
  display: none;
}
.new-search-bar-for-mobile .open-search .close-icon {
  display: none;
}
.active.new-search-bar-for-mobile .open-search .close-icon {
  display: block;
  margin: 0 auto;
  width: 18px;
}

  .new-search-bar-for-mobile .search-box button img {
    width: 20px;
}

  .new-search-bar-for-mobile .search-box button {
    padding: 0;
    background-color: transparent;
    border: solid 1px #ddd;
    border-left: none;
    border-radius: 0 40px 40px 0;
    min-width: 40px;
    text-align: left;
}

  .new-search-bar-for-mobile .search-box .search-input {
    width: 100%;
    background-color: transparent;
    border: solid 1px #ddd;
    border-right: 0;
    min-height: 35px;
    padding: 10px;
    border-radius: 40px 0 0 40px;
    color: #fff;
    height: 35px;
}
.new-search-bar-for-mobile .search-box .search-input:focus{
  outline: none;
}

  .new-search-bar-for-mobile .search-box {
    width: 100%;
    /* background-color: #000; */
    /* padding: 10px; */
    border-radius: 5px;
    display: none;
    transition: all .5s ease-in-out;
}
.active.new-search-bar-for-mobile .search-box {
  display: flex;

}
.active.new-search-bar-for-mobile{
  background-color: #000;
  padding: 15px;
}
  .sidebar-new-search-field .new-search-bar-for-mobile {
    position: fixed;
    top: 63px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all .5s ease-in-out;
    z-index: 999;

}
  .new-search-bar-for-mobile .btn.open-search {
    min-width: auto;
    height: 35px;
    min-height: inherit;
    width: 35px;
    padding: 0;
    border-radius: 40px;
    min-width: 35px;
    margin-left: 8px;
    margin-top: 10px;
    margin-right: 10px;
}
  .new-search-bar-for-mobile .btn.open-search img {
    width: 20px;
}
.active.new-search-bar-for-mobile .btn.open-search{
  margin-top: 0px;
  margin-right: 0px;
}

  .school-logo-for-mobile.fixed-scroll .school-logo-box {
    z-index: 99;
    position: fixed;
    top: 3px;
    background-color: transparent;
    box-shadow: none;
    right: 0;
    padding: 0;
    max-width: 55px;
}
.school-logo-for-mobile.fixed-scroll .school-logo-box p {
  display: none;
}
  .playlins-content-page .sidebar-for-mobile .mobile-view-logo {
    display: none !important;
}
  .home-top-details-select{
    box-shadow: none;
  }
  .form-group label.form-label{
    line-height: 40px;
    height: 40px;
  }
  .form-group select{
    height: 40px;
  }
  .video-page-main .school-logo-box,
  .practice-quetions .school-logo-box,
  .playlins-content-page .school-logo-box {
    display: block !important;
  }
  .videos-details h1{
    margin-bottom: 0;
    margin-top: 20px;
  }
  .practice-questions-box center button {
    min-width: 160px;
  }
  .school-logo-for-mobile {
    display: block;
    background-color: #fff;
    padding: 0px 10px 10px;
    transition: all .5s ease-in-out;
  }
  .success-message .success-button a.btn {
    min-width: 160px;
  }
  .success-message .success-button button.btn {
    margin-bottom: 15px;
    min-width: 180px;
  }
  .start-question-content .text-center button {
    min-width: 160px;
  }
  .start-question-content .justify-content-between button {
    min-width: 48%;
  }
  .question-right-icon ul li a {
    width: 110px;
  }
  .question-right-icon ul li a img {
    margin-bottom: 10px;
  }
  .video-next-prev-btn button {
    min-width: 48%;
  }
  .video-next-prev-btn button img {
    width: 8px;
    margin: 6px;
  }
  /* input, select, textarea, input[type="number"] {
    font-size: 16px !important;
    
  } */
  .practice-questions-box .share-link-text {
    display: none;
  }
  .question-right-icon {
    margin: 15px 0;
  }
  .question-right-icon ul li:first-child {
    margin-left: 0;
  }
  .sidebar-main1 {
    grid-template-columns: 100%;
    grid-column-gap: 0%;
  }
  .school-logo-box {
    max-width: 50%;
    margin: 0 auto;
    float: none;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: inherit;
  }
  .school-bottom-content {
    min-height: inherit;
    max-width: 100%;
    float: none;
  }
  .practice-questions-box .card {
    position: relative;
    padding-bottom: 50px;
  }
  .practice-questions-box .card a {
    max-width: 105px;
  }
  .practice-questions-box .card img.card-img-top {
    height: 85px;
    width: 85px;
    border-radius: 10px;
    object-fit: cover;
  }
  .practice-questions-box .card .card-body {
    display: block;
    padding: 5px 10px;
    text-align: left;
  }
  .practice-questions-box .card .card-body .card-title.h5 {
    margin-bottom: 15px;
  }
  .practice-questions-box .card .card-body button {
    min-width: 100%;
    border-radius: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    height: inherit;
    text-transform: uppercase;
  }
  .question-heading h1 {
    font-size: 18px;
  }
  .start-question-content {
    padding: 15px;
  }
  .ticTacLearn-testimonials-slider .slider-content p {
    padding: 0px 20px;
  }
  .ticTacLearn-testimonials-slider {
    padding: 45px 0;
  }
  div#mfn-rev-slider .home-banner .banner-contant {
    top: 10%;
    left: 10%;
    width: 90%;
  }
  .home-banner .banner-contant h1 {
    line-height: 28px;
    font-size: 24px;
  }
  .home-banner .banner-contant p {
    font-size: 13px;
  }
  div#Header_wrapper header#Header {
    min-height: inherit !important;
  }
  .content_wrapper .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .cnnt-frm button[type='submit'] {
    left: 20%;
  }
  .videos-tabs nav.nav-tabs a.nav-item {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 10px;
    border: solid 1px #ddd;
    margin-right: 3px;
    background-color: rgb(255 96 121 / 50%);
    color: #fff;
  }
  .videos-tabs nav.nav-tabs a.nav-item.active {
    background-color: #ff6079;
    color: #fff;
  }
  .videos-details .video-player .video-player-react {
    height: 200px !important;
    width: 100% !important;
  }
  #noanim-tab-example-tabpane-practice-questions .card img.card-img-top {
    padding: 0px;
    object-fit: contain;
  }
  .header-section {
    padding: 15px 0;
  }
  .portal-header a.navbar-brand img {
    max-width: 130px;
    height: 33px;
  }
  .school-logo-box img {
    max-height: 55px;
    max-width: inherit;
  }
  .school-logo-box p {
    font-size: 14px;
  }
  .sidebar-for-mobile .logo-sidebar .sidebar-main1 .sidebar-main {
    display: none;
  }
  .videos-tabs nav.nav-tabs a.nav-item span.badge {
    font-size: 10px;
    padding: 2px;
    border: solid 1px #fff;
    background-color: transparent;
  }
  .videos-tabs nav.nav-tabs a.nav-item span.badge {
    opacity: .3;
  }
  .videos-tabs nav.nav-tabs a.nav-item.active span.badge {
    opacity: 1;
  }
  .videos-tabs nav.nav-tabs a.nav-item:last-child {
    margin-right: 0;
  }
  .videos-tabs nav.nav-tabs {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .practice-questions-box .card .card-body button img {
    margin-right: 10px;
    width: 20px;
  }
  .video-player {
    padding: 0;
  }
  .success-message {
    margin: 60px 0;
  }
  .practice-questions-box .card .card-body button span {
    display: inline-block;
  }
  .sidebar-for-mobile {
    display: block;
  }
  .hide-desktop {
    display: none;
  }
  .sidebar-for-mobile button.mobile-toggle-btn {
    display: block;
    width: 100%;
    max-width: 38px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    height: 30px;
    margin-left: 10px;
    margin-top: 15px;
    padding: 0 0px 3px;
  }
  .sidebar-for-mobile button.mobile-toggle-btn:focus {
    outline: none;
  }
  .sidebar-for-mobile button.mobile-toggle-btn img {
    width: 75%;
  }
  .sidebar-for-mobile .logo-sidebar {
    z-index: 999999 !important;
    padding-top: 10px;
    width: 100%;
    max-width: 85%;
    position: fixed !important;
  }
  .sidebar-for-mobile .overlay-bg {
    z-index: 99999 !important;
  }
  .sidebar-for-mobile .logo-sidebar .sidebar-main1 {
    display: block;
  }
  .sidebar-for-mobile .toggle-btn {
    top: 0 !important;
    z-index: 11;
    height: fit-content;
    position: fixed !important;
  }
  .sidebar-for-mobile .toggle-btn button.mobile-toggle-btn {
    /* z-index: 99999; */
    /* top: 0!important; */
    /* position: fixed!important; */
    /* left: 0; */
    /* -webkit-transform: translate3d(0, 0, 0); */
    /* -o-transform: translate3d(0, 0, 0); */
    /* transform: translate3d(0, 0, 0); */
  }
  .sticky-top {
    z-index: 9 !important;
  }
  .practice-questions-box .card .card-body .card-title.h5 span {
    font-size: 14px;
  }
  /* .videos-tabs nav.nav-tabs {
    position: fixed;
    top: 43px;
    background-color: #eee;
    padding: 10px 10px 0;
    z-index: 1;
    width: 100%;
    left: 0;
  } */
  /* .videos-tabs .tab-content {
    margin-top: 32px;
  } */
  .radio-with-imgpopup label .option-images .__react_modal_image__modal_content img {
    margin: 0;
    width: 100%;
  }
  .close-sidebar .sidebar-close {
    border: none;
    background-color: #fff;
    width: 25px;
    height: 25px;
    padding: 0;
  }
  .close-sidebar .sidebar-close:focus {
    outline: none;
  }
  .close-sidebar .sidebar-close img {
    width: 100%;
  }
  .close-sidebar {
    display: block;
    text-align: right;
    margin-bottom: 15px;
  }
  p.rs-p-wp-fix {
    margin: 0;
  }
  .portal-header {
    position: fixed !important;
    top: 0;
    width: 100%;
  }
  .macking-content h2 {
    font-size: 27px;
    line-height: 36px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .macking-learning-sec {
    padding-bottom: 30px;
  }
  .Why-TicTacLearn h3 {
    font-size: 27px;
  }
  .faqs-sec, .supported-by-sec, .Partners-slider-sec, section.tictac-moments-sec, .quick-preview-sec, .tictaclearn-content, .Why-TicTacLearn {
    padding: 60px 0;
  }
  .tictaclearn-content h2 {
    font-size: 30px;
  }
  .quick-preview-content h2 {
    font-size: 30px;
  }
  .quick-preview-content p {
    font: 15px;
  }
  .tictac-moments-content h2 {
    font-size: 30px;
  }
  .moments-counter span {
    font-size: 40px;
  }
  .wep-page-header .container button.navbar-toggler {
    top: 10px;
    right: 15px;
  }
  .slick-next {
    right: -10px;
  }
  .slick-prev {
    left: -10px;
  }
  .testimonials-sec-content h3 {
    font-size: 30px;
  }
  .supported-by-sec h3, .partners-content h2 {
    font-size: 30px;
  }
  .partners-slider .slider-img img {
    width: 100%;
  }
  .stranger-form-sec {
    padding: 200px 0 100px;
    background-position: center;
  }
  .stranger-form-content h3 {
    font-size: 30px;
  }
  .faqs-content h3 {
    font-size: 30px;
  }
  .footer-sec {
    padding: 40px 0;
  }
  .footer-logo {
    margin-bottom: 30px;
  }
  .footer-menu1 {
    margin-bottom: 30px;
  }
  .slider-videos>div {
    margin: 0 auto;
  }
  div#noanim-tab-example-tabpane-playlist .card a img.card-img-top {
    height: 85px;
  }
  .home-page-main {
    margin-top: 145px;
  }
  div#mfn-rev-slider .home-banner {
    top: 70px;
  }
  .search-box .btn-search img {
    width: 25px;
}

}

@media (max-width: 320px) {
  .question-radio-group .form-check label img {
    max-width: 190px;
  }
  .videos-tabs nav.nav-tabs a.nav-item {
    font-size: 10px;
    padding: 5px;
  }
}